import * as React from 'react'
import { useEffect, useState } from 'react'

import {
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  ProgressBar,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap'

// COMPONENTSdata
import Layout from 'layouts/main'

import '../styles/components.css'
import queryString from 'querystring'
import Api from '../helpers/api'
import { config } from '../config'
import ProfilePic from '../images/profile_placeholder.png'
import axios from 'axios'

const StudentPage = () => {
  const [team, setTeam] = useState({ progress: 0 })
  const [students, setStudents] = useState([])
  const [teamObjectives, setTeamObjectives] = useState({})
  const [studentObjectives, setStudentObjectives] = useState([])

  const [selectedWeek, setSelectedWeek] = useState(0)
  const [selectedMonth, setSelectedMonth] = useState(0)

  const [opComments, setOpComments] = useState({})

  const [weeks, setWeeks] = useState([])
  const [studentImages, setStudentImages] = useState({})
  const [accessDenied, setAccessDenied] = useState(false)

  useEffect(async () => {
    try {
      const token = window.localStorage.getItem('token')
      let user = (await axios.get(`${config.API_BASE_URL}/users/profile?token=${token}`)).data
      if (user.role === 'ROLE_TRUSTEE_LIMITED') {
        setAccessDenied(true)
      }
    } catch (e) {
      const { id } = queryString.parse(window.location.search.substr(1))

      window.localStorage.removeItem('user')
      window.localStorage.removeItem('token')
      window.location.href = `/?showLogin=true&next=/team?id=${id}`
    }

  }, [])

  useEffect(() => {
    const { id } = queryString.parse(window.location.search.substr(1))

    Api.getInstance()
      .getTeams()
      .then(response => {
        setTeam(response.find(t => t.id === id))
      })
      .catch(e => {
      })
      .finally(() => {
      })
    Api.getInstance()
      .getTeamStudents(id)
      .then(response => {
        setStudents(response)
      })
      .catch(e => {
      })
      .finally(() => {
      })
    Api.getInstance()
      .getTeamObjectives(id)
      .then(response => {
        setTeamObjectives(response)
      })
      .catch(e => {
      })
      .finally(() => {
      })

    Api.getInstance().getStudentImages().then(response => {
      setStudentImages(response)
    }).catch(e => {

    }).finally(() => {

    })
  }, [])

  useEffect(() => {
    if (!teamObjectives.keyResults) {
      return
    }
    let w = []
    teamObjectives.keyResults.map(({ bi_weekly }) => {
      bi_weekly.map(({ week }) => {
        week.map(i => {
          w.push(i)
        })
      })
    })
    setWeeks(w)
  }, [teamObjectives])

  useEffect(async () => {
    let objectives = []
    let progress = 0
    let comments = {}
    for (let i = 0; i < students.length; i++) {

      const { email } = students[i]
      try {
        comments[email] = await Api.getInstance()
          .getStudentComments(email)
      } catch (e) {

      }

      let student = students[i]
      objectives.push(await Api.getInstance().getStudentObjectives(student.id))
      progress += parseFloat(student.progress)
    }

    setOpComments(comments)

    progress = parseInt(progress / students.length)
    setTeam(t => {
      return { ...t, progress }
    })
    setStudentObjectives(objectives)
  }, [students])


  let MONTHS = {
    April: ['Week 1', 'Week 2'],
    May: ['Week 3', 'Week 4', 'Week 5', 'Week 6'],
    June: ['Week 7', 'Week 8', 'Week 9', 'Week 10'],
    July: ['Week 11', 'Week 12', 'Week 13', 'Week 14'],
    August: ['Week 15', 'Week 16'],
  }

  let WEEKS_TILL = [0, 2, 6, 10, 14, 16]

  const shortNames = {
    'Product Management': 'PM',
    'Product Design': 'PD',
    'B2B Sales': 'B2B',
    'Full-Stack': 'FS',
    'Back-end': 'BE',
    'Digital Marketing': 'DM',
  }

  return (
    <Layout title='Team'>
      <Container className='pb-5'>
        <link
          href='https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css'
          rel='stylesheet'
          integrity='sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3'
          crossOrigin='anonymous'
        ></link>

        {accessDenied &&
        <div>You don't have permission to access this page.</div>
        }
        {!accessDenied &&
        <>
          <Row className='mt-5 mb-5 align-items-center'>
            <Col>
              <h3 className='mt-0 fw-medium'>{team.name}</h3>
              <p className='fs-5'>
                <a href={`/project?id=${team.id}`} className='text-dark  '>
                  {team.projectName}
                </a>
              </p>
            </Col>
            <Col md='3' className='mb-3 mb-md-0'>
              <Card className='shadow-xl border-0 rounded-xl text-center py-3 px-4'>
                <div className='d-flex mb-2 align-items-center'>
                  <ProgressBar
                    variant='warning'
                    now={team.progress}
                    className='mb-0 me-2 w-100 rounded-0'
                  />
                  <span className='align-middle'>{team.progress}%</span>
                </div>

                <p className='small mb-0'>TEAM PROGRESS</p>
              </Card>
            </Col>
            <Col md='auto'>
              <Card className='shadow-xl border-0 rounded-xl text-center py-3 px-4'>
                <dl className='row g-0 mb-0 justify-content-center'>
                  {students.map((student, index) => {
                    return (
                      <>
                        <dt className={`col-auto ${index > 0 ? 'ms-3' : ''}`}>
                          {shortNames[student.program] || student.program}:
                        </dt>
                        <dd className='col-auto mx-1'>
                          {parseInt(student.progress)}%
                        </dd>
                      </>
                    )
                  })}
                </dl>

                <p className='small mb-0'>TEAM PERFORMANCE</p>
              </Card>
            </Col>
          </Row>

          <br />

          <Row className='align-items-center d-xl-none'>
            <Col xs='12' className='d-flex justify-content-start position-relative'>
              <DropdownButton
                className='calendar-select outline-none w-100'
                variant='outline-secondary'
                title='Calendar'
                id='input-group-dropdown-1'
              >
                <div className='px-3'>
                  <FormControl
                    className='outline-none border-bottom border-top-0 border-start-0 border-end-0 rounded-0'
                    placeholder='Type a month'
                  />
                </div>
                {Object.keys(MONTHS).map((month, index) => {
                  return (
                    <Dropdown.Item
                      onClick={e => {
                        setSelectedMonth(index)
                        setSelectedWeek(WEEKS_TILL[index])
                      }}
                      key={month}
                      href='#'
                      className='py-2'
                    >
                      {month}
                    </Dropdown.Item>
                  )
                })}
              </DropdownButton>
              <div className='text-center fw-medium fs-20px position-absolute right-15px'>
                {Object.keys(MONTHS)[selectedMonth]}
              </div>
            </Col>
            <Col xs='12' className='d-flex'>
              <Tabs
                transition={false}
                className='calendar-tabs border-bottom-0 mb-3'
                activeKey={`${selectedWeek}`}
                onSelect={(selectedValue) => {
                  setSelectedWeek(parseInt(selectedValue))
                }}
              >
                {
                  MONTHS[Object.keys(MONTHS)[selectedMonth]].map((item, i) => {
                    return <Tab eventKey={`${WEEKS_TILL[selectedMonth] + i}`} key={`${WEEKS_TILL[selectedMonth] + i}`}
                                title={item}></Tab>
                  })
                }
              </Tabs>
            </Col>
          </Row>

          <Row className='mb-3 d-none d-xl-flex'>
            {Object.entries(MONTHS).map(([key, value], monthIndex) => (
              <Col xl='auto' className='mx-auto' key={monthIndex}>
                <div className='text-center fw-medium fs-16px'>
                  {key.toUpperCase()}
                </div>
                <Tabs
                  onSelect={selectedValue => {
                    setSelectedMonth(monthIndex)
                    setSelectedWeek(parseInt(selectedValue))
                  }}
                  activeKey={`${selectedWeek}`}
                  className='calendar-tabs border-bottom-0 mb-3'
                >
                  {value.map((item, i) => (
                    <Tab eventKey={`${WEEKS_TILL[monthIndex] + i}`} key={`${WEEKS_TILL[monthIndex] + i}`}
                         title={item}></Tab>
                  ))}
                </Tabs>
              </Col>
            ))}
            {/* {Object.entries(MONTHS).map(([key, value], monthIndex) => (
            <Col xl="2">

            </Col>
          ))} */}
          </Row>
          {teamObjectives.keyResults && (
            <>
              <h3 className='mt-5 pt-3 mb-4'>
                {Object.keys(MONTHS)[selectedMonth]}:{' '}
                <strong>
                  {weeks[selectedWeek] ? weeks[selectedWeek].title : ''}
                </strong>
              </h3>
              <p className='mb-5 pb-3'>
                {
                  weeks[selectedWeek] ? weeks[selectedWeek].description : ''
                }
              </p>
            </>
          )}
          {students.map((student, index) => {
            if (!studentObjectives[index]) {
              return <></>
            }
            const selectedObjective = studentObjectives[index].find(objective => objective.order === selectedWeek + 1)

            return (
              <>
                <Row className='my-md-5 my-3 align-items-center g-0'>
                  <Col xs='auto'>
                    <img
                      src={studentImages[student.email] ? `${config.CDN_BASE_URL}/${studentImages[student.email]}` : ProfilePic}
                      className='ms-1 me-3 rounded-circle float-start float-md-none'
                      style={{ width: 100, height: 100 }}
                      alt=''
                    />
                  </Col>
                  <Col>
                    <h3 className='mt-0 fw-medium'>{student.fullName}</h3>
                    <p className='fs-5'>{student.program}</p>
                  </Col>
                </Row>
                <p className='mb-5'>
                  {opComments[student.email] ? opComments[student.email].filter(({ week }) => Number(selectedWeek) === Number(week - 1))
                    .map(({ id, comment }) => {
                      return <p key={'id'}>{comment}</p>
                    }) : ''}
                </p>
                {selectedObjective &&
                <>
                  <Row>
                    {selectedObjective.keyResults.map(
                      keyResult => {
                        return (
                          <Col md='6'>
                            <Card className='px-4 py-3 rounded-xl mb-3 border-darker'>
                              <Row>
                                <Col>
                                  <h4 className='fs-18px fw-medium'>
                                    {keyResult.title}
                                  </h4>
                                </Col>
                                <Col xs='auto' className='text-center'>
                                  <h5
                                    className={`${
                                      keyResult.done
                                        ? 'text-success'
                                        : 'text-danger'
                                    } mb-0 fw-bold`}
                                  >
                                    {keyResult.done ? 'Done' : 'Not Done'}
                                  </h5>
                                </Col>
                              </Row>
                              <hr className='mt-3' />
                              <p className='fst-italic fw-light'>
                                {keyResult.description}
                              </p>
                            </Card>
                          </Col>
                        )
                      },
                    )}
                  </Row>
                </>
                }
              </>
            )
          })}
        </>
        }
      </Container>
    </Layout>
  )
}

export default StudentPage
